exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-arcturus-drone-js": () => import("./../../../src/pages/projects/arcturus-drone.js" /* webpackChunkName: "component---src-pages-projects-arcturus-drone-js" */),
  "component---src-pages-projects-electric-go-kart-js": () => import("./../../../src/pages/projects/electric-go-kart.js" /* webpackChunkName: "component---src-pages-projects-electric-go-kart-js" */),
  "component---src-pages-projects-internal-combustion-engine-js": () => import("./../../../src/pages/projects/internal-combustion-engine.js" /* webpackChunkName: "component---src-pages-projects-internal-combustion-engine-js" */),
  "component---src-pages-projects-jewelry-case-js": () => import("./../../../src/pages/projects/jewelry-case.js" /* webpackChunkName: "component---src-pages-projects-jewelry-case-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-mit-robotics-comp-js": () => import("./../../../src/pages/projects/mit-robotics-comp.js" /* webpackChunkName: "component---src-pages-projects-mit-robotics-comp-js" */),
  "component---src-pages-projects-oyster-flipping-robot-js": () => import("./../../../src/pages/projects/oyster-flipping-robot.js" /* webpackChunkName: "component---src-pages-projects-oyster-flipping-robot-js" */),
  "component---src-pages-projects-personal-desk-js": () => import("./../../../src/pages/projects/personal-desk.js" /* webpackChunkName: "component---src-pages-projects-personal-desk-js" */),
  "component---src-pages-projects-robotic-arm-js": () => import("./../../../src/pages/projects/robotic-arm.js" /* webpackChunkName: "component---src-pages-projects-robotic-arm-js" */),
  "component---src-pages-projects-shoelace-tightener-js": () => import("./../../../src/pages/projects/shoelace-tightener.js" /* webpackChunkName: "component---src-pages-projects-shoelace-tightener-js" */),
  "component---src-pages-projects-tank-inspired-robot-js": () => import("./../../../src/pages/projects/tank-inspired-robot.js" /* webpackChunkName: "component---src-pages-projects-tank-inspired-robot-js" */),
  "component---src-pages-projects-yoyo-manufacturing-js": () => import("./../../../src/pages/projects/yoyo-manufacturing.js" /* webpackChunkName: "component---src-pages-projects-yoyo-manufacturing-js" */)
}

